import { t } from '@lingui/macro';

interface SweetAlertOptions {
  cancelButtonText: string;
  customClass: string;
  showCancelButton: boolean;
  text: string;
  title: string;
  type: string;
  allowOutsideClick?: boolean;
  closeOnConfirm?: boolean;
  confirmButtonText?: string;
  showConfirmButton?: boolean;
}

interface OptionsArguments {
  allowOutsideClick?: boolean;
  cancelButtonText?: string;
  closeOnConfirm?: boolean;
  customClass?: string;
  onLeaveCopy?: string;
  onStayCopy?: string;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  successCopy?: string;
  successTitle?: string;
  thingToDelete?: string;
  thingToRemove?: string;
}

export const deleteOptions = (argument?: OptionsArguments): SweetAlertOptions => ({
  cancelButtonText: t`Cancel`,
  closeOnConfirm: argument?.closeOnConfirm ?? true,
  confirmButtonText: t`Delete`,
  customClass: 'deleting',
  showCancelButton: argument?.showCancelButton ?? true,
  text: t`Are you sure you want to delete ${argument?.thingToDelete ?? ''}?`,
  title: t`Are you sure?`,
  type: 'warning',
});

export const removeOptions = (argument?: OptionsArguments): SweetAlertOptions => ({
  cancelButtonText: t`Cancel`,
  closeOnConfirm: argument?.closeOnConfirm ?? true,
  confirmButtonText: t`Remove`,
  customClass: 'removing',
  showCancelButton: argument?.showCancelButton ?? true,
  text: t`Are you sure you want to remove ${argument?.thingToRemove ?? ''}?`,
  title: t`Are you sure?`,
  type: 'warning',
});

export const resetOptions = (argument?: OptionsArguments): SweetAlertOptions => ({
  cancelButtonText: t`Cancel`,
  closeOnConfirm: argument?.closeOnConfirm ?? true,
  confirmButtonText: t`Reset`,
  customClass: 'resetting',
  showCancelButton: argument?.showCancelButton ?? true,
  text: argument?.thingToDelete ?? '',
  title: t`Are you sure?`,
  type: 'warning',
});

// TODO: possible that default values won't update when locale changes
export const unsavedChangesOptions = ({
  closeOnConfirm,
  onLeaveCopy = t`Discard and Close`,
  onStayCopy = t`Continue Editing`,
  showCancelButton,
}: OptionsArguments): SweetAlertOptions => ({
  cancelButtonText: onStayCopy,
  closeOnConfirm: closeOnConfirm ?? true,
  confirmButtonText: onLeaveCopy,
  customClass: 'unsaved-changes',
  showCancelButton: showCancelButton ?? true,
  text: t`Are you sure you want to discard changes?`,
  title: t`Unsaved Changes`,
  type: 'warning',
});

// TODO: possible that default values won't update when locale changes
export const successOptions = ({
  allowOutsideClick = true,
  cancelButtonText = t`Close`,
  customClass,
  showCancelButton,
  showConfirmButton,
  successCopy = t`Action successfully completed!`,
  successTitle = t`Success!`,
}: OptionsArguments): SweetAlertOptions => ({
  type: 'success',
  title: successTitle,
  text: successCopy,
  showCancelButton: showCancelButton ?? true,
  showConfirmButton: showConfirmButton ?? false,
  cancelButtonText,
  allowOutsideClick,
  ...customClass && { customClass },
});

export const mergeAssetOptions = (): SweetAlertOptions => ({
  cancelButtonText: t`Don't Merge`,
  confirmButtonText: t`Merge`,
  closeOnConfirm: true,
  customClass: 'merging',
  showCancelButton: true,
  text: t`There are multiple files with the same filename, do you want them merged together?`,
  title: t`Merge Attachments`,
  type: 'warning',
});
